import React from 'react'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { getChildrenToRender } from './utils'

class Content00 extends React.PureComponent {
  componentDidMount() {

  }

  render() {
    const { ...props } = this.props
    const { dataSource, isMobile } = props
    delete props.dataSource
    delete props.isMobile

    const ItemOneAnimType = {
      queue: isMobile ? 'bottom' : 'left',
      one: isMobile
        ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
        : {
          x: '+=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
    }
    const ItemOneTweenOne = (
      <TweenOne
        key="img"
        animation={ItemOneAnimType.one}
        resetStyle
        {...dataSource.content_item1_Wrapper.imgWrapper}
        component={Col}
        componentProps={{
          md: dataSource.content_item1_Wrapper.imgWrapper.md,
          xs: dataSource.content_item1_Wrapper.imgWrapper.xs,
        }}
      >
        <span {...dataSource.content_item1_Wrapper.img}>
          <img src={dataSource.content_item1_Wrapper.img.children} width="100%" alt="img" />
        </span>
      </TweenOne>
    )
    const ItemOneQueueAnim = (
      <QueueAnim
        type={ItemOneAnimType.queue}
        key="text"
        leaveReverse
        ease={['easeOutCubic', 'easeInCubic']}
        {...dataSource.textWrapper}
        component={Col}
        componentProps={{
          md: dataSource.textWrapper.md,
          xs: dataSource.textWrapper.xs,
        }}
      >
        <h2 key="h1" {...dataSource.content_item1_Wrapper.contentTitle}>
          {dataSource.content_item1_Wrapper.contentTitle.children}
        </h2>
        <div key="p" {...dataSource.content_item1_Wrapper.content}>
          {dataSource.content_item1_Wrapper.content.children}
        </div>
      </QueueAnim>
    )

    const ItemTwoAnimType = {
      queue: isMobile ? 'bottom' : 'right',
      one: isMobile
        ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
        : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
    }
    const ItemTwoTweenOne = (
      <TweenOne
        key="img"
        animation={ItemTwoAnimType.one}
        resetStyle
        {...dataSource.content_item2_Wrapper.imgWrapper}
        component={Col}
        componentProps={{
          md: dataSource.content_item2_Wrapper.imgWrapper.md,
          xs: dataSource.content_item2_Wrapper.imgWrapper.xs,
        }}
      >
        <span {...dataSource.content_item2_Wrapper.img}>
          <img src={dataSource.content_item2_Wrapper.img.children} width="100%" alt="img" />
        </span>
      </TweenOne>
    )
    const ItemTwoQueueAnim = (
      <QueueAnim
        key="text"
        type={ItemTwoAnimType.queue}
        leaveReverse
        ease={['easeOutQuad', 'easeInQuad']}
        {...dataSource.textWrapper}
        component={Col}
        componentProps={{
          md: dataSource.textWrapper.md,
          xs: dataSource.textWrapper.xs,
        }}
      >
        <h2 key="h1" {...dataSource.content_item2_Wrapper.contentTitle}>
          {dataSource.content_item2_Wrapper.contentTitle.children}
        </h2>
        <div key="p" {...dataSource.content_item2_Wrapper.content}>
          {dataSource.content_item2_Wrapper.content.children}
        </div>
      </QueueAnim>
    )

    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>

          {/* itme1 */}
          <OverPack {...dataSource.OverPack} component={Row}>
            { isMobile ? (
              <>
                {ItemOneTweenOne}
                {ItemOneQueueAnim}
              </>
            ) : (
              <>
                {ItemOneTweenOne}
                {ItemOneQueueAnim}
              </>
            ) }
          </OverPack>
          {/* itme1 */}

          {/* itme2 */}
          <OverPack {...dataSource.OverPack} component={Row}>
            { isMobile ? (
              <>
                {ItemTwoTweenOne}
                {ItemTwoQueueAnim}
              </>
            ) : (
              <>
                {ItemTwoQueueAnim}
                {ItemTwoTweenOne}
              </>
            ) }
          </OverPack>
          {/* itme2 */}

        </div>
      </div>
    )
  }
}

export default Content00
