import React from 'react'
import QueueAnim from 'rc-queue-anim'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { getChildrenToRender } from './utils'

class Content extends React.PureComponent {
  getBlockChildren = (data) => {
    return data.map((item, i) => {
      const { children } = item
      return (
        <Col key={i.toString()} {...item}>
          <div {...children.icon}>
            <img src={children.icon.children} width="100%" alt="img" />
          </div>
          <h4 {...children.title}>{children.title.children}</h4>
          <div {...children.content}>{children.content.children}</div>
        </Col>
      )
    })
  };

  render() {
    const { ...props } = this.props
    const { dataSource } = props
    delete props.dataSource
    delete props.isMobile
    const listChildren = this.getBlockChildren(dataSource.block.children)
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          <OverPack {...dataSource.OverPack}>
            <QueueAnim
              type="bottom"
              key="block"
              leaveReverse
              {...dataSource.block}
              component={Row}
            >
              {listChildren}
              {/* <Link
                href={dataSource.button.href}
                to={dataSource.button.href}
                smooth
              >
                <Button
                  type="ghost"
                  key="button"
                  shape="round"
                  size="large"
                  icon="more"
                >
                  {dataSource.button.children}
                </Button>
              </Link> */}
            </QueueAnim>

          </OverPack>

        </div>

      </div>
    )
  }
}

export default Content
