import React from 'react'
// import { Typography } from 'antd';
// const { Paragraph,Title } = Typography;

export const TrackRecordDataSource = {
  wrapper: { className: 'home-page-wrapper TrackRecord-wrapper' },
  page: { className: 'home-page TrackRecord' },
  OverPack: { playScale: 0.3, className: '' },

  titleWrapper: {
    className: 'TrackRecord-title-background',
    children: {
      className: 'TrackRecord-title-text',
      children: (<h1>Track Record</h1>),
    },
  },

  textWrapper: {
    className: 'TrackRecord-content-wrapper',
    children: [
      {
        col_img: {
          md: 6,
          xs: 24,
          align: 'middle',
          children: {
            img: {
              src: require('../../static/images/Icon-6.png'),
            }
          }
        },
        col_content: {
          md: 18,
          xs: 24,
          children: {
            h1: {
              className: 'TrackRecord-content-wrapper-title',
              children: 'Taipower Project',
            },
            h2: {
              className: 'TrackRecord-content-wrapper-littleTitle',
              children: 'Evaluation and demonstration of renewable energy generation monitoring and estimating system',
            },
            content: {
              className: 'TrackRecord-content-wrapper-text',
              children: `
                  The outstanding ability of our company have been recognized by the Taiwan Power 
                  Corporation, together for the goal of renewable energy analytical development, providing photovoltaic related 
                  analysis experience to help the renewable energy development.
                  `,
            },
          },
        },
      },
      {
        col_img: {
          md: 6,
          xs: 24,
          align: 'middle',
          children: {
            img: {
              src: require('../../static/images/Icon-7.png'),
            }
          }
        },
        col_content: {
          md: 18,
          xs: 24,
          children: {
            h1: {
              className: 'TrackRecord-content-wrapper-title',
              children: 'EU PVSEC 2019',
            },
            h2: {
              className: 'TrackRecord-content-wrapper-littleTitle',
              children: 'Real-time Fault Detection in Massive Multi-Array PV Plants based on Machine Learning Techniques',
            },
            content: {
              className: 'TrackRecord-content-wrapper-text',
              children: `
                  In order to reduce costs associated with operations, maintenance and downtime, and to 
                  increase the performance of PVS, our team works with the academic research community, 
                  combines artificial intelligence and advanced algorithms to develop a new approach of 
                  fault detection. This research provides knowledge and technology contributions to the 
                  academic research community. It is particularly recognized by the European Photovoltaic 
                  Solar Energy Conference and Exhibition and participated in the 36th in 2019 the EU PVSEC 
                  paper was published.
                  `,
            },
          },
        },
      },
    ]
  }
}
