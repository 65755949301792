/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
// import Page from 'react-page-loading'
import { enquireScreen } from 'enquire-js'

import TrackRecord from './TrackRecord'

import {
  TrackRecordDataSource,
} from './DataSource'

import './less/AntMotionStyle.less'

let isMobile
enquireScreen((b) => {
  isMobile = b
})

const { location } = window

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    })

    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        })
      }, 500)
    }
  }

  render() {
    const Mobile = this.state
    const children = [
      <TrackRecord
        id="TrackRecord"
        key="TrackRecord"
        dataSource={TrackRecordDataSource}
        // isMobile={this.state.isMobile}
        isMobile={Mobile.isMobile}
      />,
    ]
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {Mobile.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}

        {/* <Page
          loader={"bubble-spin"}
          color={"#A9A9A9"}
          size={4}
        >
        </Page> */}
      </div>
    )
  }
}
