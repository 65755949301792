/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { enquireScreen } from 'enquire-js'
import { withRouter } from 'react-router'
import Header from './Home/Header'
import Footer from './Home/Footer'
import Home from './Home'
import ContactUs from './Page/ContactUs'
import TrackRecord from './Page/TrackRecord'
import {
  NavDataSource,
  FooterDataSource,
} from './Home/DataSource'

let isMobile
enquireScreen((b) => {
  isMobile = b
})

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    const mediaCondition = 'only screen and (max-width: 900px)'
    // const mediaCondition = 'only screen and (max-width: 767px)';

    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    }, mediaCondition)
  }

  render() {
    const { pathname } = this.props.location
    return (
      <Router>
        <Header
          id="Nav"
          key="Nav"
          dataSource={NavDataSource}
          isMobile={this.state.isMobile}
        />
        <Switch>
          <Route
            exact
            path="/"
            component={Home}
          />

          <Route
            path="/ContactUs"
            component={ContactUs}
          />

          <Route
            path="/TrackRecord"
            component={TrackRecord}
          />

          <Route
            path="*"
            component={Home}
          />
        </Switch>

        <Footer
          id="Footer"
          key="Footer"
          dataSource={FooterDataSource}
          isMobile={this.state.isMobile}
        />
      </Router>

    )
  }
}

export default withRouter(App)
