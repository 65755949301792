import React from 'react'
import { Icon } from 'antd'
import QueueAnim from 'rc-queue-anim'
import TweenOne, { TweenOneGroup } from 'rc-tween-one'
import BannerAnim, { Element } from 'rc-banner-anim'
import { HashLink } from 'react-router-hash-link'
import { isImg } from './utils'
import 'rc-banner-anim/assets/index.css'

const { BgElement } = Element
class Banner extends React.PureComponent {
  render() {
    const { ...props } = this.props
    const { dataSource, isMobile } = props
    delete props.dataSource
    delete props.isMobile
    const childrenToRender = dataSource.BannerAnim.children.map((item, i) => {
      const elem = item.BannerElement
      const elemClassName = elem.className
      delete elem.className
      const { bg } = item
      const { textWrapper } = item
      const { title } = item
      const { content } = item

      const follow = {
        delay: 1000,
        minMove: 0.1,
        data: [
          {
            id: `bg${i}`,
            value: !isMobile ? 15 : 0,
            type: 'x',
          },
          {
            id: `wrapperBlock${i}`,
            value: !isMobile ? -15 : 0,
            type: 'x'
          },
        ],
      }

      return (
        <Element
          key={i.toString()}
          followParallax={follow}
          {...elem}
          prefixCls={elemClassName}
        >

          <BgElement key="bg" {...bg} id={`bg${i}`} />
          <QueueAnim
            type={['bottom', 'top']}
            delay={200}
            key="text"
            {...textWrapper}
            id={`wrapperBlock${i}`}
          >
            <div key="logo" {...title}>
              {typeof title.children === 'string'
                && title.children.match(isImg) ? (
                  <img src={title.children} width="100%" alt="img" />
                ) : (
                  title.children
                )}
            </div>
            <div key="content" {...content}>
              {content.children}
            </div>
          </QueueAnim>
        </Element>
      )
    })
    return (
      <div {...props} {...dataSource.wrapper}>
        <TweenOneGroup
          key="bannerGroup"
          enter={{ opacity: 0, type: 'from' }}
          leave={{ opacity: 0 }}
          component=""
        >
          <BannerAnim
            type="across"
            key="BannerAnim"
            {...dataSource.BannerAnim}
            autoPlay
            autoPlayEffect={false}
            dragPlay
            autoPlaySpeed={7500}
          >
            {childrenToRender}
          </BannerAnim>
        </TweenOneGroup>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner2-icon"
          style={{ bottom: 40 }}
          key="icon"
        >
          <HashLink
            href="/#HomeStrategy"
            to="/#HomeStrategy"
            smooth
            style={{ color: '#fff' }}
          >
            <Icon type="down" />
          </HashLink>
        </TweenOne>
      </div>
    )
  }
}

export default Banner
