import React from 'react'
import { Row, Col, Typography } from 'antd'

class TrackRecord extends React.PureComponent {
  render() {
    const { ...props } = this.props
    const { dataSource } = props
    delete props.dataSource
    delete props.isMobile
    const textData = dataSource.textWrapper.children
    const textWrapperChildren = textData.map((object, i) => {
      return (
        <div key={i} {...dataSource.textWrapper}>
          <Row type="flex" justify="space-around" align="middle">
            <Col {...object.col_img}>
              <img alt="img" {...object.col_img.children.img} />
            </Col>
            <Col {...object.col_content}>
              <Typography.Title level={2} {...object.col_content.children.h1}>
                {object.col_content.children.h1.children}
              </Typography.Title>
              <Typography.Title level={4} {...object.col_content.children.h2}>
                {object.col_content.children.h2.children}
              </Typography.Title>
              <Typography.Paragraph {...object.col_content.children.content}>
                {object.col_content.children.content.children}
              </Typography.Paragraph>
            </Col>
          </Row>
        </div>
      )
    })

    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>

          <div {...dataSource.titleWrapper}>
            <div {...dataSource.titleWrapper.children} />
          </div>

          { textWrapperChildren }
        </div>

      </div>
    )
  }
}

export default TrackRecord
