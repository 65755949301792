import dayjs from 'dayjs'
import React from 'react'

const handleClick = () => {
  // const nav = document.querySelector('.ant-menu-item:nth-child(2)')
  const nav = document.querySelector('.TrackRecord')
  const actNav = document.querySelector('.ant-menu-item-selected')
  if (actNav !== null) {
    actNav.classList.remove('ant-menu-item-selected')
    actNav.classList.add('disabled')
  }
  console.log(actNav)
  // console.log(nav)
  // nav.classList.add('ant-menu-item-active');
  nav.classList.add('ant-menu-item-selected')

  // nav.addEventListener('mouseout',()=>{
  //   nav.classList.add('ant-menu-item-active');
  //   nav.classList.add('ant-menu-item-selected');
  // })
}

const navClick = () => {
  const navs = document.querySelectorAll('.ant-menu-item')
  navs.forEach((nav) => {
    // console.log(nav)
    nav.classList.remove('ant-menu-item-selected')
    nav.classList.remove('ant-menu-item-active')
  })
  // console.log(navs);
}

export const NavDataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo-page',
  },
  logoImg: {
    className: 'header0-logo',
    img: {
      src: require('../static/images/Logo.png'),
      // width: "75px",
      width: '275px',
    },
  },
  logoTitle: {
    className: 'header0-title',
    // children: 'Renewable energy data solution provider',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      { name: 'item0', a: { children: 'Home', href: '/' } },
      // { name: 'item0', className:'ant-menu-item-selected',a: { children: 'Home', href: '/' } },
      // { name: 'item1', a: { children: 'Product', href: '/Product' } },
      { name: 'item2', className: 'TrackRecord', a: { children: 'Track Record', href: '/TrackRecord' } },
      { name: 'item3', a: { children: 'Contact Us', href: '/ContactUs' } },
      // { name: 'item3', className:'ant-menu-item-active',a: { children: 'Contact Us', href: '/ContactUs' } },
      // { name: 'item3', className:{ this.props.location.pathname==='/ContactUs'? 'ant-menu-item-active' : '' },a: { children: 'Contact Us', href: '/ContactUs' } },
    ],
    onClick: navClick
  },
  mobileMenu: { className: 'header0-mobile-menu' },
}
export const BannerDataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner2-text-wrapper page1' },
        bg: { className: 'bg bg0' },
        title: { className: 'banner2-title', children: 'Photovoltaic System' },
        content: {
          className: 'banner2-content',
          children: 'Photovoltaic is set to become the largest source of renewable electricity generation in the future',
        },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner2-text-wrapper page1' },
        bg: { className: 'bg bg1' },
        title: { className: 'banner2-title', children: 'Artificial Intelligence' },
        content: {
          className: 'banner2-content',
          children: 'Renewable energy data combined with Artificial Intelligence can provide high efficiency solution',
        },
      },
      {
        name: 'elem3',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner2-text-wrapper page1' },
        bg: { className: 'bg bg3' },
        title: { className: 'banner2-title', children: 'Solution Provider' },
        content: {
          className: 'banner2-content',
          children: 'Power your renewable energy portfolios through Reforecast data solutions',
        },
      },

    ],
  },
}
export const HomeStrategyDataSource = {
  wrapper: { className: 'home-page-wrapper content00-wrapper' },
  page: { className: 'home-page content00' },
  OverPack: { className: 'home-page content00', playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper content00-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>Reforecast Strategy</p>
          </>
        ),
      },
    ],
  },
  textWrapper: { className: 'content00-text', md: 14, xs: 24 },

  content_item1_Wrapper: {
    imgWrapper: { className: 'content00-img', md: 10, xs: 24 },
    img: {
      children: require('../static/images/Icon-1.png'),
    },
    contentTitle: {
      className: 'content00-title',
      children: 'Provide Solution',
    },
    content: {
      className: 'content00-content',
      children:
        'Analysis and forecast renewable energy data and provide data solutions to stakeholders',
    },
  },

  content_item2_Wrapper: {
    imgWrapper: { className: 'content00-img', md: 10, xs: 24 },
    img: {
      children: require('../static/images/Icon-2.png'),
    },
    contentTitle: {
      className: 'content00-title',
      children: 'Focused Work',
    },
    content: {
      className: 'content00-content',
      children:
        'Currently focused on distributed photovoltaic plants monitoring and power generation data solutions',
    },
  },
}
export const HomeProductDataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  button: {
    href: '/Product',
    children: 'Learn More',
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>Reforecast Product</p>
          </>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
              require('../static/images/Icon-3.png'),
          },
          title: {
            className: 'content0-title',
            children: (
              <>
                <p>Monitoring System</p>
              </>
            ),
          },
          content: {
            className: 'content0-text',
            children: (
              <>
                <p>photovoltaic plant monitoring system and private cloud</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
              require('../static/images/Icon-4.png'),
          },
          title: {
            className: 'content0-title',
            children: (
              <>
                <p>Fault Detection System</p>
              </>
            ),
          },
          content: {
            className: 'content0-text',
            children: (
              <>
                <p>photovoltaic plant advanced fault detection system</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
              require('../static/images/Icon-5.png'),
          },
          title: {
            className: 'content0-title',
            children: (
              <>
                <p>Assets Evaluation System</p>
              </>
            ),
          },
          content: {
            className: 'content0-text',
            children: (
              <>
                <p>photovoltaic plant financial assets evaluation system (Pre-alpha)</p>
              </>
            ),
          },
        },
      },
    ],
  },
}

export const HomeTrackRecordDataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  button: {
    // href: '/Track_Record',
    children:
  <span>Learn More</span>,
    href: '/TrackRecord',
    onClick: handleClick
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>Reforecast Track Record</p>
          </>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              require('../static/images/Icon-6.png'),
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>Taipower Project</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children:
              'Evaluation and demonstration of renewable energy generation monitoring and estimating system',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              require('../static/images/Icon-7.png'),
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>EU PVSEC 2019</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children:
              'Real-time Fault Detection in Massive Multi-Array photovoltaic Plants based on Machine Learning Techniques',
          },
        },
      },
    ],
  },
}

// const myFunction = () => {
//   let d = new Date();
//   let n = d.getFullYear();
//   let value=document.getElementsByClassName("copyright");
//   return `${n}`
// }

export const FooterDataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <span>
          Copyright ©
          {' '}
          {dayjs().get('year')}
          {' '}
          Reforecast All Right Reserved.
        </span>
      </>
    ),
  },
}
