import React from 'react'
import TweenOne from 'rc-tween-one'
import { HashLink as Link } from 'react-router-hash-link'
import { Row, Col } from 'antd'
import { Menu } from 'antd'

const { Item } = Menu

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneOpen: false,
      menuHeight: 0,
    }
    this.menu = React.createRef()
  }

  componentDidMount() {
    // 如果是 react 16.3 以下版本请使用 findDOMNode;
    // this.menuDom = findDOMNode(this.menu);
  }

  phoneClick = () => {
    const Phone = this.state
    // const phoneOpen = !this.state.phoneOpen
    const phoneOpen = !Phone.phoneOpen
    this.setState({
      phoneOpen,
      menuHeight: phoneOpen ? this.menu.current.dom.scrollHeight : 0,
    })
  };

  render() {
    const { ...props } = this.props
    const { dataSource, isMobile } = props

    delete props.dataSource
    delete props.isMobile
    const { menuHeight, phoneOpen } = this.state
    const navData = dataSource.Menu.children
    const navChildren = Object.keys(navData).map((key, i) => {
      return (
        <Item key={i.toString()} {...navData[key]}>
          <Link
            key={i.toString()}
            {...navData[i]}
            href={navData[i].a.href}
            to={navData[i].a.href}
            smooth
          >
            {navData[i].a.children}
          </Link>
        </Item>
      )
    })

    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
          style={{ maxWidth: '100%' }}
        >
          <TweenOne
            {...dataSource.logo}
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
          >
            <a href="/">
              <Row type="flex" align="middle">
                <Col
                  {...dataSource.logoImg}
                  span={6}
                  align="middle"
                >
                  <img alt="img" {...dataSource.logoImg.img} />
                </Col>

                {/* <Col
                  {...dataSource.logoTitle}
                  span={18}
                ><h1>{dataSource.logoTitle.children} </h1></Col> */}
              </Row>
            </a>
          </TweenOne>
          {isMobile && (
            /* eslint-disable-next-line jsx-a11y / no-static-element-interactions */
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick()
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={{ x: 30, type: 'from', ease: 'easeOutQuad' }}
            ref={this.menu} // {(c) => { this.menu = c; }}
            style={isMobile ? { height: menuHeight } : null}
          >

            <Menu
              mode={isMobile ? 'inline' : 'horizontal'}
              theme={isMobile ? 'dark' : 'default'}
            >
              {navChildren}
            </Menu>

          </TweenOne>
        </div>

      </TweenOne>
    )
  }
}

export default Header
