/* eslint-disable react/destructuring-assignment */
/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
// import Page from 'react-page-loading'

import { enquireScreen } from 'enquire-js'
import Banner from './Banner'
import HomeStrategy from './HomeStrategy'
import HomeProduct from './HomeProduct'
import HomeTrackRecord from './HomeTrackRecord'

import {
  BannerDataSource,
  HomeStrategyDataSource,
  HomeProductDataSource,
  HomeTrackRecordDataSource,
} from './DataSource'

import './less/AntMotionStyle.less'

const { location } = window
let isMobile
enquireScreen((b) => {
  isMobile = b
})

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: !location.port, // 如果不是 dva 2.0 请删除
      isMobile,
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    const mediaCondition = 'only screen and (max-width: 900px)'
    // const mediaCondition = 'only screen and (max-width: 767px)';

    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    }, mediaCondition)

    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        })
      }, 500)
    }
  }

  render() {
    const children = [
      <Banner
        id="Banner"
        key="Banner"
        dataSource={BannerDataSource}
        isMobile={this.state.isMobile}
      />,
      // -----Renewable Energy Strategy-----
      <HomeStrategy
        id="HomeStrategy"
        key="HomeStrategy"
        dataSource={HomeStrategyDataSource}
        isMobile={this.state.isMobile}
      />,
      // -----Renewable Energy Strategy-----

      // -----Renewable Energy Product-----
      <HomeProduct
        id="HomeProduct"
        key="HomeProduct"
        dataSource={HomeProductDataSource}
        isMobile={this.state.isMobile}
      />,
      // -----Renewable Energy Product-----

      // -----Renewable Energy Track Record-----
      <HomeTrackRecord
        id="HomeTrackRecord"
        key="HomeTrackRecord"
        dataSource={HomeTrackRecordDataSource}
        isMobile={this.state.isMobile}
      />,
      // -----Renewable Energy Track Record-----
    ]

    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d
        }}
      >
        {/* <ReactLoading type={"balls"} color={"#FF0000"} height={'20%'} width={'20%'} /> */}

        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}

        {/* <Page
          loader={"bubble-spin"}
          color={"#A9A9A9"}
          size={4}
        >
        </Page> */}
      </div>
    )
  }
}
