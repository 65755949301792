import React from 'react'
import { Button, Form, Col } from 'react-bootstrap'
import { message } from 'antd'
import { stringify } from 'qs'

class ContactUs extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      formLastName: '',
      formFirstName: '',
      formEmail: '',
      formSubject: '',
      formMessage: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const onChangeName = event.target.name
    const onChangeValue = event.target.value

    this.setState({
      [onChangeName]: onChangeValue
    })
  }

  handleSubmit() {
    const {
      formLastName,
      formFirstName,
      formEmail,
      formMessage,
      formSubject
    } = this.state

    const hasData = formLastName && formLastName !== ''
      && formFirstName && formFirstName !== ''
      && formEmail && formEmail !== ''
      && formMessage && formMessage !== ''

    if (hasData) {
      const params = {
        name: `${formLastName} ${formFirstName}`,
        email: formEmail,
        subject: formSubject,
        message: formMessage,
      }
      fetch(
        `https://script.google.com/macros/s/AKfycbySU7eA2wtySoEdyHopRe0vTP1ucUgikoy_nYdgIaV98AneXTWRL1cBATBczseRzg6q1A/exec?${stringify(params)}`,
        {
          mode: 'no-cors',
          method: 'GET',
        },
      ).then(() => {
        message.success('OK!')
      }).catch((e) => {
        console.log(e)
        return e
      })
    } else {
      message.warning('Did not complete the form.')
    }
  }

  render() {
    const { ...props } = this.props
    const { dataSource } = props
    const {
      formLastName,
      formFirstName,
      formEmail,
      formMessage,
      formSubject
    } = this.state
    delete props.dataSource
    delete props.isMobile

    const BootstrapForm = (
      <Form>
        <Form.Label>Name</Form.Label>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridLastName">
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              name="formLastName"
              value={formLastName}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formBasicFirstName">
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              name="formFirstName"
              value={formFirstName}
              onChange={this.handleChange}
            />
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Email"
            name="formEmail"
            value={formEmail}
            onChange={this.handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formBasicSubject">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Subject"
            name="formSubject"
            value={formSubject}
            onChange={this.handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formBasicMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            placeholder="Enter Message"
            name="formMessage"
            value={formMessage}
            onChange={this.handleChange}
          />
        </Form.Group>

        <Button
          variant="primary"
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </Form>
    )

    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>

          <div {...dataSource.titleWrapper}>
            <div {...dataSource.titleWrapper.children} />
          </div>

          <div {...dataSource.textWrapper}>
            <h3 {...dataSource.textWrapper.children.div_introduction}>
              {dataSource.textWrapper.children.div_introduction.children}
            </h3>
          </div>

          <div {...dataSource.textWrapper}>
            {BootstrapForm}
          </div>
        </div>
      </div>
    )
  }
}

export default ContactUs
