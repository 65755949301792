import React from 'react'

export const ContactUsDataSource = {
  wrapper: { className: 'home-page-wrapper ContactUs-wrapper' },
  page: { className: 'home-page ContactUs' },
  OverPack: { playScale: 0.3, className: '' },

  titleWrapper: {
    className: 'ContactUs-title-background',
    children: {
      className: 'ContactUs-title-text',
      children: (<h1>Contact Us</h1>),
    },
  },

  textWrapper: {
    className: 'ContactUs-textWrapper',
    children: {
      div_introduction: {
        className: 'ContactUs-textWrapper-introduction',
        children:
  <>
    To learn more about the renewable energy solutions, please feel free to contact us.
  </>,
      }
    },
  },

}
